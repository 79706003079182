.react-datepicker {
  font-family: unset;
  font-size: 1rem;
  padding: 1em;
  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: #007dc5;
}

.react-datepicker-popper {
  z-index: 999;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__input-container {
  font-size: 20px;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: 0;
}

.react-datepicker__header--custom {
  background-color: white;
  border-bottom: 0;
}

.react-datepicker__month {
  margin: 0;
  display: grid;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: 0;
  border-left: 0.5px solid rgba(0, 0, 0, 0.1);
  border-right: 0.5px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  width: 6em;
}

.react-datepicker__week {
  display: flex;
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
}

.react-datepicker__day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin: 0;
  padding: 0;
  border-radius: 0;
}

.react-datepicker__day:hover {
  background-color: #2aab87;
  border-radius: 0;
  opacity: 0.8;
}

.react-datepicker__day--disabled {
  background-color: #f2f2f2;
}

.react-datepicker__day--disabled:hover {
  background-color: #f2f2f2;
  opacity: 1;
}

.react-datepicker__day--selected {
  background-color: #2aab87;
  color: white;
}

.react-datepicker__day--selected:hover {
  background-color: #2aab87;
  color: white;
}

.react-datepicker__day--outside-month {
  background-color: #f2f2f2;
  color: transparent !important;
  pointer-events: none;
}

.react-datepicker__day--outside-month:hover {
  background-color: #f2f2f2;
  color: #f2f2f2;
  opacity: 1;
  cursor: default;
}

.react-datepicker__day-names {
  margin-top: 8px;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  border-left: 0.5px solid rgba(0, 0, 0, 0.1);
  border-right: 0.5px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0;
  background-color: #f2f2f2;
  display: flex;
}

.react-datepicker__day-name {
  color: #007dc5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin: 0;
  padding: 0;
}
