.le-wiki {
    cursor: pointer;
    background-color: rgba(0,0,255,0.1);
    color: rgba(0,0,255,0.7);
    padding: 0.25rem;
    border-radius: 2px 2px 0 0;
    border-bottom: 1px dashed rgba(0,0,255,0.7);
    font-size: 90%;
}

.le-bold {
    font-weight: 700 !important;
}
.le-emphasis {
    font-style: italic;
}
.le-underline {
    text-decoration: underline;
}

.le-blog-post ul, .le-blog-post ol {
    margin: 0 2rem 2rem;
}

.le-phonenumber {
    color: rgba(8,125,197,1);
    border-bottom: 1px dashed rgba(8,125,197,1);
}

.le-phonenumber:hover {
    border-bottom: 1px solid rgba(8,125,197,1);
}

.le-blog-img {
    border-radius: 4px;
    padding: 1px;
    border: 1px solid #ddd;
}

.le-blog-post {
    margin-bottom: 24px;
}

.le-blog-date {
    font-size: 11pt;
    font-weight: 500;
}

.le-blog-author {
    font-size: 14pt;
}

.le-blog-link {
    font-weight: 600;
    color: var(--chakra-colors-royalBlue);
}

.le-blog-link:hover {
    text-decoration: underline;
}

.le-blog-table {
    border-collapse: collapse;
    border-radius: 4px;
    display: table;
    width: 100%;
    table-layout: fixed;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #99acc2;
}

.le-blog-table tr:nth-child(odd) { background-color: #eff7ff; }
.le-blog-table tr:nth-child(even) { background-color: #ffffff; }

.le-blog-table td {
    padding: 4px 8px;
    border: 1px solid #d2e9fc;
    text-align: center;
}

.le-blog-table td.le-blog-table-hdr {
    font-weight: 700;
    font-size: 110%;
}

.le-blog-post p, 
.le-blog-post li,
.le-blog-table td {
    font-size: 12pt;
    line-height: 1.5;
}

.le-post-summary p, 
.le-post-summary li,
.le-post-summary td {
    font-size: 11pt;
    line-height: 1.2;
    color: #2c2b20;
}

.le-blog-post img {
    margin-bottom: 1rem;
}

.le-blog-callout {
    background: #20315a;
    position: relative;
    padding-left: 8px;
    border-radius: 2px 0 0 2px;
}

.le-blog-callout::after {
    background: #20315a;
    content: "";
    width: 48px;
    height: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 29px solid #20315a;
    border-right: 15px solid #ffffff;
}

.careers-hero::after {
    background: transparent;
    content: "";
    width: 84px;
    height: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 84px solid rgba(8,125,197,1);
    border-right: 42px solid transparent;
}

h2.le-h2 {
    font-size: 2.25rem;
}

p.le-para {
    width: 100%;
}

p.le-para:has(> .le-actr), p.le-para:has(> .le-alft), p.le-para:has(> .le-argt) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}