.automatic-messaging > span {
  opacity: 0.5;
  font-weight: 300;
  font-size: 95%;
}

.automatic-messaging > span:last-child {
  opacity: 1;
  font-weight: 400;
  font-size: 100%;
}

.calculator-step {
    opacity: 0;
    position: relative;
    bottom: -200px;
}

.calculator-step.animate {
    opacity: 1;
    transition: all 270ms ease-in-out;
    bottom: 0;
}

.calculator-step.animate-unwind {
    opacity: 0;
    transition: all 270ms ease-in-out;
    bottom:-200px
}

.estimator-tilt-shake {
    animation: tilt-shaking 1.1s infinite;
}

.estimator-pop-pre {
    transition: 200ms all ease-in-out;
    opacity: 0;
}

.estimator-pop-pre.estimator-pop {
    animation: pop-spin 0.5s ease-in-out 1;
    opacity: 1;
}

.loading-run {
    transform: translateX(0);
    animation: loading-run ease-in-out infinite 3s;
    position: absolute;
  }

  .loading-run.out {
    transition: all 200ms ease-in-out;
    opacity: 0;
    transform: translateY(300px)
  }

@keyframes tilt-shaking {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(0.25deg); }
    75% { transform: rotate(0.75deg); }
    100% { transform: rotate(0deg); }
}

@keyframes pop-spin {
    25% { transform: scale(0.5);}
    75% { transform: scale(2);}
}

@keyframes loading-run {
    0% {
      transform: translateX(0%);
    }
    15% {
        transform: translateX(25%);
      }
    37% {
        transform: translateX(19%);
      }
    50% {
      transform: translateX(57%);
    }
    64% {
        transform: translateX(100%);
      }
      78% {
        transform: translateX(23%);
      }
    100% {
        transform: translateX(0%);
    }
  }

  .btn-dynamic {
    opacity: 0;
    position: relative;
    bottom: -100px;
    transition: all 270ms ease-in-out;
  }

  .btn-dynamic.go {
    opacity: 1;
    bottom: 0;
  }

  .lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
  }
.line {
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  opacity: .2;
}
.line::after {
    content: '';
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    animation: drop 7s 0s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
  
  .line:nth-child(1) {
    margin-left: -25%;
  }
  .line:nth-child(1)::after {
    animation-delay: 2s;
  }
  
  .line:nth-child(3) {
    margin-left: 25%;
  }
  .line:nth-child(3)::after {
    animation-delay: 2.5s;
  }
  
    
@keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}
